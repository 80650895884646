import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function About() {
  return (
    <Layout title="About">
      <SEO title="contact Anjan Dutta" />
      <p>Welcome to my blog! I am Anjan, a front-end developer from India. In my blog, 
        I write about Javascript and front-end programming articles to help other developers.</p>

      <h3>Work and Experience</h3>
      <p>– Associate Lead, Technology at Nagarro</p>
      <p>– Working on web technologies since 2010.</p>
      <p>– Work as a full stack developer.</p>
      <p>– TechStack: Angular, Node, MongoDB, Express, Python</p>

      <h3>Spare Time</h3>
      <p>While not coding, you will find me either playing Age of Mythology or reading something 
        interesting. Even though I am a foodie, I consider cooking as a hobby. Like to travel a lot, 
        it’s food for my thought. Favorite TV show – Suits. Yeah, waited long enough for those GOT junkies to piss on my excitement.</p>

      <h3>.com?</h3>
      <p>– To share knowledge, experience and work that I find challenging to solve.</p>
      <p>– Share update of side projects.</p>
      <p>– A piece of code that I find helpful.</p>    
    </Layout>
  );
}